<template>
 <div
    class="wrapper"
    style="width: 100%;"
  >
  <div class="container mt-4">
    <div class="row header" :style="styleObject">
      <div class="col center-col"></div>
      <div class="col center-col">
        <div class="dropdown p-4" id="card">
          <div class="row">
            <div class="col"></div>
            <div class="col center-col">
              <!-- <img class="img-logo" src="../../assets/img/logo_app.png" /> -->
              <img v-if="!device" class="img-logo" :src="logo" />
              <img v-else class="img-logo-mobile" :src="logo" />
            </div>
            <div class="col"></div>
          </div>
          <form class="px-4 py-3" @submit.prevent="search">
            <div class="mb-3">
              <input
                type="text"
                class="form-control"
                id="inputCode"
                v-model.trim="code"
                :placeholder="$t(`search.code`)"
              />
            </div>
            <button
              type="submit"
              class="btn button"
              :style="{ backgroundColor: colorCompany }"
            >
              {{ $t("search.search") }}
            </button>
          </form>
        </div>
      </div>
      <div class="col center-col"></div>
    </div>
          <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div class="footer-legal" style="text-align:center;"> 
        <p style="line-height: 37pt; font-size: 8pt" class="a-legal">
          <a class="a-legal" :href="hrefLegal" target="_blank">{{
            $t("legal.legalWarn")
          }}</a>
          -
          <a class="a-legal" :href="hrefCookies" target="_blank">{{
            $t("legal.pcookies")
          }}</a>
          -
          <a class="a-legal" :href="hrefPrivacy" target="_blank">{{
            $t("legal.pprivacity")
          }}</a>
          -
          Developed by <a class="a-legal" href="https://www.consultoriacomunica.com" target="_blank">Comunica Consultoría y Desarrollo S.L.</a>          
        </p>
      </div>
          <br />
      <br />
      <br />
      <br />
      <br />
      <br />
  </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      code: "",
      emp_id: this.$route.params.idemp,
      device: false,
      hrefPrivacy: "",
      hrefLegal: "",
      hrefCookies: "",
    };
  },
  created() {
    this.hrefPrivacy = `/politica-privacidad.asp?id=${this.emp_id}`;
    this.hrefLegal = `/aviso-legal.asp?id=${this.emp_id}`;
    this.hrefCookies = `/politica-cookies.asp?id=${this.emp_id}`;    
    this.changeBreadCrumb(22);
    this.littleDrop();
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // true for mobile device
      this.device = true;
    } else {
      // false for not mobile device
      this.device = false;
    }
    // this.verLogo();
  },
  computed: {
    ...mapState(["colorCompany", "id_emp", "logo"]),
    styleObject() {
      if (this.device) {
        return {
          "--margin-top": '20pt'
        };
      } else {
        return {
          "--margin-top": '120pt'
        };
      }
    },
  },
  methods: {
    ...mapMutations(["littleDrop", "changeBreadCrumb"]),
    search() {
      if (this.code === "") {
        return;
      }
      this.$router.push(`/${this.emp_id}/whistleblower/${this.code}`);
    },
    // verLogo() {
    //   this.logo = `${this.servidor}api/ver_logotipo.asp?emp_id=${this.emp_id}`;
    //   console.log("logo", this.logo);
    // }
  },
};
</script>

<style scoped>
#card {
  width: 500px;
  border-radius: 30px;
  background-color: white;
  
}

.header {
  margin-top: var(--margin-top);
}

.field-icon {
  float: right;
  margin-top: -69px;
  position: relative;
  z-index: 2;
  left: -11px;
  cursor: pointer;
}

.icon-name {
  float: left;
  top: 31px;
  position: relative;
  left: 11px;
}

.icon-password {
  float: left;
  top: 31px;
  position: relative;
  left: 11px;
}

.button {
  padding: 10px;
  width: 100%;
  border-radius: 50px;
  color: #fff;
  background-color: #fbb03a;
}

.label {
  font-size: 10pt;
  color: #a4a9a8;
  margin-top: 19pt;
  float: right;
}

input {
  height: 35pt;
  text-indent: 50px;
}

.center-col {
  display: flex;
  justify-content: center;
}

.img-fondo {
  margin-top: 72pt;
  width: 100%;
  height: 75%;
}

.img-logo {
  width: 300pt;
  padding: 35pt;
}

.img-logo-mobile {
  width: 215pt;
  padding: 35pt;
}
a {
  color: black;
}

</style>